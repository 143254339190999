import { messageError, translation } from '@/utils';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { InstallationOrderList } from '@/resource/model';
import { Component, Vue } from 'vue-property-decorator';
import { Message } from 'element-ui';
import { installationOrderService } from '@/api';
import { TagsViewModule } from '@/store/modules/tags-view';
import { Route } from 'vue-router/types/router';
Component.registerHooks(['beforeRouteLeave']);
@Component({})
export default class BatchEditPrice extends Vue {
  public $table!: OsTable;
  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<InstallationOrderList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true
  };

  public price = '';

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<InstallationOrderList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'installationOrder.code',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'projectName',
      label: 'installationOrder.projectName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'installationOrder.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'installationOrder.address',
      minWidth: '250px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const data: InstallationOrderList = row as InstallationOrderList;

        return `${data.province}${data.city}${data.district}${data.address}`;
      }
    },
    {
      prop: 'amount',
      label: 'installationOrder.installPrice',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<InstallationOrderList> = [];

  private needReloadList = false;

  public created(): void {
    if (this.$route.query.orderList) {
      this.tableOption.data = JSON.parse(this.$route.query.orderList as string);
    } else {
      this.goBack();
    }
  }

  public mounted(): void {
    this.$table = this.$refs.tableRef as OsTable;
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<InstallationOrderList>): void {
    this.selectedRows = selectedData;
  }

  public goBack(): void {
    this.$router.back();
  }

  public handleBatchSetPrice(): void {
    if (!this.price) {
      Message.warning(translation('installationOrder.inputQuotedPrice'));
      return;
    }

    if (this.selectedRows.length > 0) {
      this.selectedRows.forEach(item => {
        item.amount = Number(this.price);
      });
    } else {
      this.tableOption.data.forEach(item => {
        item.amount = Number(this.price);
      });
    }
  }

  public async handleSubmit(): Promise<void> {
    try {
      const data = this.tableOption.data.map(item => {
        return {
          id: item.id,
          amount: item.amount
        };
      });
      await installationOrderService.batchEditPrice(data);
      Message.success(translation('operationRes.editSuccess'));
      this.needReloadList = true;
      this.$router.go(-1);
      TagsViewModule.DelView(this.$route);
    } catch (error) {
      messageError(error);
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/installation-order') {
      to.meta!.needReload = this.needReloadList;
    }
    next();
  }
}
